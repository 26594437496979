@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
body {
  margin: 0;
  padding: 0;
  background-color: #302c2c;
}
@font-face {
  font-family: sf-pro;
  src: url("../assets/fonts/SFPRODISPLAYBOLD.otf") format("opentype");
}
.navbar-container {
  width: 100%;
  height: 84px;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.edvora-name {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: sf-pro;
  font-size: 36px;
  margin-left: 43px;
  margin-top: 21px;
  margin-bottom: 20px;
}
.navbar-profile-container {
  display: flex;
  flex-direction: row;
}
.navbar-name {
  color: #ffffff;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  align-self: center;
}
.navbar-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  margin-left: 25px;
}
