@font-face {
  font-family: sf-pro;
  src: url("../assets/fonts/SFPRODISPLAYBOLD.otf") format("opentype");
}
.filter-cont {
  width: 228px;
  height: 190px;
  border-radius: 15px;
  background-color: #131313;
  position: absolute;
  right: 20px;
}
.filter-text {
  font-family: sf-pro;
  font-size: 20px;
  margin-top: 23px;
  color: #a5a5a5;
  font-weight: 300;
  width: 60%;
}
.underline {
  width: 70%;
  height: 1px;
  background-color: #a5a5a5;
  margin-top: -10px;
  margin-left: 30px;
}
.dropdown {
  background-color: #232323;
  width: 170px;
  border-width: 0px;
  margin-top: 20px;
  height: 37px;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: sf-pro;
}
.dropdown:focus {
  outline: none;
}
.dropdown-option {
  color: #ffffff;
  font-size: 15px;
  font-family: sf-pro;
}
