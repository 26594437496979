@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
.ride {
  width: 100%;
  height: 198px;
  background-color: #171717;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ride-img {
  width: 296px;
  height: 148px;
  margin-left: 29px;
  border-radius: 5px;
}
.ride-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.ride-text {
  font-size: 14px;
  font-family: "Inter";
  color: #cfcfcf;
  margin: 5px 0;
}
.ride-rl-cont {
  margin-left: 25px;
}
.ride-rr-cont {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 20px;
}
.btn {
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 16px;
  height: 23px;
  padding: 4px 10px;
}
.btn-name {
  color: white;
  font-family: "Inter";
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}
